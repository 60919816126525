const breakpoints = [480, 768, 992, 1200];

export const mq = (index) => {
  const width = breakpoints[index];

  if (!width) {
    return '';
  }

  return `@media (min-width: ${width}px)`;
};
