import * as React from 'react';

export function useEmailObfuscator(email: string) {
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.currentTarget.href = `mailto:${email}`;
    },
    [email]
  );

  const href = React.useMemo(() => `mailto:${email.replace('@', '[at]')}`, [email]);

  return {
    email,
    href,
    handleClick,
  };
}
