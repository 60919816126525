import * as React from 'react';
import { Global, css } from '@emotion/react';

export const GlobalCSS = () => (
  <Global
    styles={css`
      html,
      body {
        font-family: Asap, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;
      }

      body {
        display: flex;
        flex-direction: column;
      }

      p {
        line-height: 1.4;
        padding-bottom: 16px;
      }

      a {
        color: #f59492;
      }
    `}
  />
);
