import * as React from 'react';
import { Link } from 'gatsby';
import { mq } from './utils';
import { Container } from '../Container';
import logo from '../../images/stichting-mijngilde-logo.svg';

const NavLink = (props) => (
  <React.Fragment>
    <Link
      css={{
        fontSize: 18,
        display: 'block',
        padding: 12,
        color: '#666',
        textDecoration: 'none',

        '&.active': {
          color: '#f59492',
          fontWeight: 'bold',
        },

        '&.active:after': {
          display: 'block',
          content: '""',
          backgroundColor: '#f59492',
          height: '2px',
          width: '60%',
        },
      }}
      activeClassName="active"
      {...props}
    />
  </React.Fragment>
);

export const Header = () => (
  <header css={{ backgroundColor: '#fff', paddingTop: '12px', paddingBottom: '24px' }}>
    <Container
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 6,
        [mq(1)]: { flexDirection: 'row', justifyContent: 'space-between' },
      }}
    >
      <Link to="/" css={{ display: 'inline-block', padding: 6 }}>
        <img
          src={logo}
          alt="Stichting MijnGilde logo"
          css={{ width: '208px', [mq(0)]: { width: '278px' } }}
        />
      </Link>

      <nav
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          [mq(0)]: { flexWrap: 'no-wrap' },
        }}
      >
        <NavLink to="/">Home</NavLink>
        <NavLink to="/organisatie">Organisatie</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </nav>
    </Container>
  </header>
);
