import * as React from 'react';
import { Container } from '../Container';
import { SectionSplitter, LeftSection, RightSection, Title } from '../Section';
import { useEmailObfuscator } from '../../hooks/useEmailObfuscator';
import mijnGildePartnerLogo from '../../images/partners/mijngilde-partner.svg';
import anbiLogo from '../../images/anbi-logo.svg';

export const Footer = () => {
  const obfuscator = useEmailObfuscator('bestuur@stichtingmijngilde.nl');

  return (
    <footer
      css={{
        flexShrink: 0,
        backgroundColor: '#ececec',
        padding: '24px 0',
        a: {
          color: '#d46a68',
        },
      }}
    >
      <Container>
        <SectionSplitter>
          <LeftSection>
            <Title>Contact</Title>
            <address css={{ fontStyle: 'normal' }}>
              Stichting MijnGilde
              <br />
              Postadres: Het Zool 49, 8939 BL Leeuwarden
              <br />
              Telefoon: 06-52401129
              <br />
              Mail:{' '}
              <a href={obfuscator.href} onClick={obfuscator.handleClick}>
                {obfuscator.email}
              </a>
            </address>
          </LeftSection>

          <RightSection>
            <Title>Gesteund door:</Title>
            <div css={{ display: 'flex', alignItems: 'center', paddingTop: '6px' }}>
              <a href="https://mijngilde.nl" target="_blank">
                <img src={mijnGildePartnerLogo} width="198px" alt="MijnGilde logo" />
              </a>

              <div css={{ paddingLeft: '32px' }}>
                <img src={anbiLogo} alt="Stichting MijnGilde is een algemeen nut beogende instelling (ANBI)." width="100px" />
              </div>
            </div>
          </RightSection>
        </SectionSplitter>
      </Container>
    </footer>
  );
};
