import * as React from 'react';
import { mq } from '../Layout';

export const Section = (props) => <section css={{ paddingBottom: '24px' }} {...props} />;

export const SectionSplitter = (props) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      padding: 6,
      [mq(1)]: { flexDirection: 'row' },
    }}
    {...props}
  />
);

export const LeftSection = (props) => (
  <Section
    css={{ flex: '1 1 50%', paddingLeft: '6px', [mq(1)]: { paddingRight: '18px' } }}
    {...props}
  />
);
export const RightSection = (props) => (
  <Section
    css={{
      flex: '1 1 50%',
      paddingLeft: '6px',
      [mq(1)]: { paddingLeft: '18px', paddingRight: '6px' },
    }}
    {...props}
  />
);

export * from './Title';
