import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ResetCSS } from './ResetCSS';
import { GlobalCSS } from './GlobalCSS';
import appleTouchIcon from '../../images/icons/apple-touch-icon.png';
import favicon32x32 from '../../images/icons/favicon-32x32.png';
import favicon16x16 from '../../images/icons/favicon-16x16.png';
import safariPinnedTab from '../../images/icons/safari-pinned-tab.svg';

export const Layout = (props) => (
  <>
    <Helmet titleTemplate="%s - Stichting MijnGilde - Inzetten voor een duurzame wereld">
      <html lang="nl" />
      <link href="https://fonts.googleapis.com/css?family=Asap:400,600" rel="stylesheet" />
      <meta charSet="utf-8" />
      <meta name="description" content="Oog op participatie & duurzaamheid." />
      <meta name="keywords" content="help, charity" />
      <meta property="og:title" content="Stichting MijnGilde - Inzetten voor een duurzame wereld" />
      {/* <meta property="og:image" content={ogImage} /> */}
      <meta property="og:site_name" content="Stiching MijnGilde" />
      <meta property="og:description" content="Oog op participatie & duurzaamheid." />
      <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
      <link rel="mask-icon" href={safariPinnedTab} color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#603cba" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
    <ResetCSS />
    <GlobalCSS />

    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
      }}
      {...props}
    />
  </>
);
export { PageTitle, PageSubTitle } from './PageTitle';
export { Header } from './Header';
export { Main } from './Main';
export { Footer } from './Footer';
export { mq } from './utils';
