import * as React from 'react';

export const Title = (props) => (
  <h1
    css={{
      fontSize: '24px',
      paddingBottom: '12px',
      '&:after': {
        display: 'block',
        content: '""',
        backgroundColor: '#F59492',
        height: '2px',
        width: '45px',
      },
    }}
    {...props}
  />
);
